export enum DateFormats {
    SHORT = 'MM/dd/y, h:mm a', // 06/15/2015, 9:03 AM
    MEDIUM = 'MMM d, y, h:mm:ss a', // Jun 15, 2015, 9:03:01 AM
    LONG = 'MMMM d, y, h:mm:ss a z', // June 15, 2015 at 9:03:01 AM GMT+1
    FULL = 'EEEE, MMMM d, y, h:mm:ss a zzzz', // Monday, June 15, 2015 at 9:03:01 AM GMT+01:00
    SHORT_DATE = 'yyyy-MM-dd', // 2015-06-15
    MEDIUM_DATE = 'MMM d, y', // Jun 15, 2015
    LONG_DATE = 'MMMM d, y', // June 15, 2015
}

export const DATE_PICKER_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthLabel: 'MMMM',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
