<vc-dialog
    [loading]="loading"
    [visible]="visible"
    [title]="dialogTitle"
    [primaryLabel]="primaryButtonText"
    secondaryLabel="Cancel"
    i18n-secondaryLabel="@@CORE.BUTTON.CANCEL"
    [width]="'500px'"
    [closeOnPrimary]="false"
    [showSecondary]="showSecondaryTrigger"
    [showCloseIcon]="showCloseIcon"
    (visibleChange)="visibleChange.emit($event)"
    (primaryTrigger)="nextStep()">
    <div
        *ngIf="currentStep === mfaStep.INTRO"
        class="vc-enable-mfa-dialog-wrapper body-m-1"
        i18n="@@ACCOUNT.ENABLE_MFA_DESCRIPTION">
        This process requires the use of an authenticator application on your device. Examples are Google Authenticator
        or Authy. Please ensure you have that installed before clicking the next button.
    </div>
    <div *ngIf="currentStep === mfaStep.QRCODE" class="vc-enable-mfa-dialog-wrapper body-m-1">
        <qrcode
            style="display: flex; justify-content: center; height: 200px"
            [allowEmptyString]="true"
            [qrdata]="qrData"
            [width]="200"
            [errorCorrectionLevel]="'M'"
            [vcProgressSpinner]="!qrData"></qrcode>
        <span i18n="@@ACCOUNT.ENABLE_MFA_PLEASE_SCAN">
            Please scan the QR code with your authenticator application. Once the authenticator has enrolled this
            account, click next.
        </span>
    </div>
    <div *ngIf="currentStep === mfaStep.VERIFY" class="vc-enable-mfa-dialog-wrapper body-m-1">
        <vc-input
            i18n-label="@@ACCOUNT.ENABLE_MFA_VERIFICATION_CODE"
            label="Verification Code"
            [(value)]="verificationCode"
            [required]="true"></vc-input>
        <span i18n="@@ACCOUNT.ENABLE_MFA_ENTER_CODE">Please enter the current authenticator code and click next.</span>
    </div>
    <div *ngIf="currentStep === mfaStep.END" class="vc-enable-mfa-dialog-wrapper body-m-1">
        <span i18n="@@ACCOUNT.ENABLE_MFA_COMPLETED">
            You have successfully completed your multi-factor authentication. Press Log out and log back in to continue.
        </span>
    </div>
</vc-dialog>
