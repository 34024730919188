<vc-common-form
    #userDetailsForm
    ariaLabeledBy="Details"
    i18n-primaryLabel="@@CORE.BUTTON.SAVE"
    primaryLabel="Save"
    [showButtons]="showButtons && !readonly"
    [showPrimaryButton]="showPrimaryButton"
    [clearFieldsOnSecondary]="false"
    [showSecondaryButton]="showSecondaryButton"
    (primaryTrigger)="validateAndSubmit($event)"
    (secondaryTrigger)="resetDetails()">
    <div class="vc-user-details-form-wrapper">
        <vc-input
            class="space-bottom-8"
            i18n-label="@@CORE.INPUT.USERNAME"
            label="Username"
            [required]="!readonly"
            [readonly]="readonly || readonlyUsername"
            [maxLength]="255"
            [(value)]="username"
            (valueChange)="user.userKey.username = $event"></vc-input>
        <vc-password
            *ngIf="showPassword"
            class="space-bottom-8"
            label="Password"
            i18n-label="@@LOGIN.FORM.PASSWORD"
            i18n-description="@@LOGIN.PASSWORD_REQUIREMENTS_TEXT"
            description="Password must be 8 to 32 characters, contain at least 1 upper case, 1 lower case, 1 number and 1 special character."
            [required]="passwordRequired"
            [autocomplete]="'new-password'"
            [(value)]="password"
            [maxLength]="32"
            [minLength]="8"
            [minNumbers]="1"
            [minUpper]="1"
            [minSpecial]="1"
            [minLower]="1"
            [showVisibleButton]="true"
            [showClear]="true"
            (valueChange)="user.password = $event"></vc-password>
        <vc-input
            class="space-bottom-8"
            i18n-label="@@CORE.INPUT.EMAIL"
            label="Email"
            [readonly]="readonly"
            [maxLength]="255"
            [required]="true"
            [validationFunction]="validateEmail"
            [(value)]="email"
            (valueChange)="user.email = $event"></vc-input>
        <vc-common-autocomplete
            *ngIf="showOrg"
            class="space-bottom-8"
            label="Organization"
            i18n-label="@@CORE.INPUT.ORGANIZATION"
            placeholder="Search for organization"
            i18n-placeholder="@@ORGANIZATION.USERS.SEARCH_FOR_ORGANIZATION"
            [readonly]="readonly || readonlyOrg"
            [(value)]="org"
            [options]="orgs"
            [required]="orgRequired"
            [showClear]="true"
            [suffixIcon]="readonlyOrg ? '' : 'search'"
            [itemRenderer]="orgRenderer"
            (valueChange)="updateOrg($event)"
            (filterChange)="filterChange.emit($event)"></vc-common-autocomplete>
        <vc-input
            class="space-bottom-8"
            i18n-label="@@CORE.INPUT.FIRSTNAME"
            label="First name"
            [readonly]="readonly"
            [required]="firstNameRequired"
            [maxLength]="255"
            [(value)]="firstName"
            (valueChange)="user.firstName = $event"></vc-input>
        <vc-input
            class="space-bottom-8"
            i18n-label="@@CORE.INPUT.LASTNAME"
            label="Last name"
            [readonly]="readonly"
            [required]="lastNameRequired"
            [maxLength]="255"
            [(value)]="lastName"
            (valueChange)="user.lastName = $event"></vc-input>
        <vc-select
            *ngIf="showLanguageSelector"
            class="space-bottom-8"
            label="Default Language"
            i18n-label="@@CORE.INPUT.DEFAULT_LANGUAGE"
            [options]="languages"
            [(value)]="language"
            [prefixTemplate]="languagePrefixTemplate"
            [optionTemplate]="languageTemplate"
            (valueChange)="updateLanguage($event)"></vc-select>
        <vc-phone
            class="space-bottom-16"
            i18n-numberLabel="@@CORE.INPUT.PHONE_NUMBER"
            numberLabel="Phone number"
            [readonly]="readonly"
            [maxNumberLength]="40"
            [(value)]="primaryPhone"
            [showExt]="true"
            (valueChange)="updatePrimaryPhone($event)"></vc-phone>
        <ng-content select="[userDetailsActions]"></ng-content>
        <vc-common-textarea
            *ngIf="showNotes"
            i18n-label="@@CORE.INPUT.NOTES"
            label="Notes"
            rows="3"
            [readonly]="readonly"
            [(value)]="notes"
            (valueChange)="user.notes = $event"></vc-common-textarea>
    </div>
</vc-common-form>
<ng-template #languageTemplate let-option>
    <vc-country-flag [countryClass]="option === 'en' ? 'us' : option"></vc-country-flag>
    <span>{{ getLanguageLabel(option) }} </span>
</ng-template>
<ng-template #languagePrefixTemplate let-value>
    <vc-country-flag [countryClass]="value === 'en' ? 'us' : value"></vc-country-flag>
</ng-template>
