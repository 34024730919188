import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VcCoreLibModule, WhoAmI } from '@libs/vc-core-lib';
import { IOrgModel } from '@libs/vc-org-lib';
import { TreeNode, VcCommonUiLibModule } from '@libs/vc-common-ui-lib';
import { CommonModule } from '@angular/common';
import { TagsSelectorModule } from '../tags-selector';
import { isEmpty } from 'lodash-es';
import { BoldBySearch } from '../bold-by-search/bold-by-search.pipe';

interface OrgNode {
    term: string;
    name: string;
    id: string;
    tags: string[];
}

@Component({
    selector: 'vc-org-picker',
    templateUrl: 'org-picker.component.html',
    styleUrls: ['org-picker.component.scss'],
    standalone: true,
    imports: [
        VcCommonUiLibModule,
        VcCoreLibModule,
        CommonModule,
        TagsSelectorModule,
        BoldBySearch,
    ],
})
export class OrgPickerComponent {
    private _previousHighlighted!: OrgNode | null;
    readonly filterLabel: string = $localize`:@@COMMON_UI.TREE.FILTER:Filter`;
    readonly filterPlaceholder: string = $localize`:@@COMMON_UI.TREE.FILTER:Filter`;

    orgTreeNodes: TreeNode<OrgNode>[] = [];
    selectedOrgNode!: OrgNode | null;
    filterValue!: string;

    @Input()
    loading: boolean = false;

    @Input()
    set selectedOrg(org: IOrgModel | null) {
        this._selectedOrg = org;
        !isEmpty(this.orgTreeNodes) && this._setHighlightedNode(this.orgTreeNodes);
    }
    get selectedOrg(): IOrgModel | null {
        return this._selectedOrg;
    }
    private _selectedOrg!: IOrgModel | null;

    @Input()
    user!: WhoAmI;

    @Input()
    set visible(value: boolean) {
        if (this._visible !== value) {
            this._visible = value;
            !this._visible && this._refreshSelection();
            this.visibleChange.emit(this._visible);
        }
    }

    get visible(): boolean {
        return this._visible;
    }

    private _visible: boolean = false;

    @Input()
    set orgModels(values: IOrgModel[]) {
        this._orgModels = values;
        this._initOrgTree();
    }

    get orgModels(): IOrgModel[] {
        return this._orgModels;
    }

    private _orgModels: IOrgModel[] = [];

    @Input()
    title: string = $localize`:@@CORE.USER.CHANGE_ORGANIZATION:Change organization`;

    @Input()
    primaryLabel: string = $localize`:@@COMMON_UI.SAVE:Save`;

    @Input()
    showTags: boolean = false;

    /** Outputs filter value to allow BE search; Will search existing list by default **/
    @Input()
    searchByFilter: boolean = false;

    @Output()
    filterValueChange = new EventEmitter<string>();

    @Output()
    visibleChange = new EventEmitter<boolean>();

    @Output()
    selectedOrgChange = new EventEmitter<IOrgModel>();

    selectOrg() {
        if (this.selectedOrgNode?.id) {
            const selectedOrg = this.orgModels.find((org) => org.id === this.selectedOrgNode?.id);
            selectedOrg && this.selectedOrgChange.emit(selectedOrg);
        }
    }

    orgItemRenderer = (value: OrgNode) => value?.name ?? '';

    private _convertToTreeView(data: TreeNode<OrgNode>[], list: IOrgModel[]) {
        list.forEach((record: IOrgModel) => {
            let paths = record.paths.filter((pdata: string) => pdata.startsWith('/org/'));
            let path: string = paths[0].trim().substring(5);
            let pathTerms: Array<string> = path.split('/');
            let currentData = data;

            pathTerms.forEach((pathTerm: string) => {
                let found: boolean = false;
                currentData.forEach((d: TreeNode<OrgNode>) => {
                    if (d?.data?.term == pathTerm) {
                        currentData = d.children ?? [];
                        found = true;
                    }
                });

                if (!found) {
                    let org: IOrgModel | undefined = list.find((org: IOrgModel) => org.id === pathTerm);
                    if (org) {
                        let newData: TreeNode<OrgNode> = {
                            data: {
                                term: pathTerm,
                                name: org.name,
                                id: org.id,
                                tags: org.tags,
                            },
                            children: [],
                            selectable: true,
                            iconPrefix: 'business',
                        };
                        currentData.push(newData);
                        currentData = newData.children ?? [];
                    }
                }
            });
        });
    }

    private _refreshSelection() {
        if (this._previousHighlighted && this.selectedOrgNode?.id != this._previousHighlighted?.id) {
            this.selectedOrgNode = this._previousHighlighted;
        }
    }

    private _initOrgTree() {
        this.selectedOrgNode = null;
        this.orgTreeNodes = [];
        this._convertToTreeView(this.orgTreeNodes, this.orgModels);
        this._setHighlightedNode(this.orgTreeNodes);
    }

    private _setHighlightedNode(values: TreeNode<OrgNode>[]) {
        const orgId = this.user?.getOrgId();
        values.forEach((node: TreeNode<OrgNode>) => {
            if (node.data?.id === orgId) {
                this.selectedOrgNode = node.data;
                this._previousHighlighted = node.data;
            }

            if (node?.children && node?.children?.length > 0) {
                this._setHighlightedNode(node?.children);
            }

            if (!this.selectedOrg) {
                this.selectedOrgNode = null;
            }
        });
    }
}
