import { CommonUtil } from '../util/common.util';

export enum Severity {
    UNKNOWN = 'UNKNOWN',
    INFO = 'INFO',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    CRITICAL = 'CRITICAL',
}

export class SeverityHelper {
    static getSeverityLocale(severity: Severity): string {
        switch (severity) {
            case Severity.CRITICAL:
                return $localize`:@@CORE.SEVERITIES.CRITICAL:Critical`;
            case Severity.HIGH:
                return $localize`:@@CORE.SEVERITIES.HIGH:High`;
            case Severity.MEDIUM:
                return $localize`:@@CORE.SEVERITIES.MEDIUM:Medium`;
            case Severity.LOW:
                return $localize`:@@CORE.SEVERITIES.LOW:Low`;
            case Severity.INFO:
                return $localize`:@@CORE.SEVERITIES.INFO:Info`;
            case Severity.UNKNOWN:
                return $localize`:@@CORE.SEVERITIES.UNKNOWN:Unknown`;
            default:
                return '';
        }
    }

    static getSeverityBadgeIcon(severity: Severity): string {
        switch (severity) {
            case Severity.CRITICAL:
                return 'keyboard_double_arrow_up';
            case Severity.HIGH:
                return 'keyboard_arrow_up';
            case Severity.MEDIUM:
                return 'drag_handle';
            case Severity.LOW:
                return 'keyboard_double_arrow_down';
            case Severity.INFO:
                return 'info';
            case Severity.UNKNOWN:
                return 'help_outline';
            default:
                return '';
        }
    }

    static getSeverityBadgeStatus(severity: Severity): string {
        switch (severity) {
            case Severity.CRITICAL:
                return 'error';
            case Severity.HIGH:
                return 'error';
            case Severity.MEDIUM:
                return 'warning';
            case Severity.LOW:
                return 'success';
            case Severity.INFO:
                return 'info';
            case Severity.UNKNOWN:
                return 'neutral';
            default:
                return 'neutral';
        }
    }

    static getSeverityColor(severity: Severity): string {
        switch (severity) {
            case Severity.CRITICAL:
                return '#A40A02';
            case Severity.HIGH:
                return CommonUtil.getRenderedColor('--error-text-color');
            case Severity.MEDIUM:
                return '#EEA417';
            case Severity.LOW:
                return CommonUtil.getRenderedColor('--success-color');
            case Severity.INFO:
                return CommonUtil.getRenderedColor('--primary-700');
            case Severity.UNKNOWN:
                return CommonUtil.getRenderedColor('--secondary-200');
            default:
                return CommonUtil.getRenderedColor('--secondary-200');
        }
    }
}
