import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthorityUtil, Language, Notification, TagUtil, Theme, WhoAmI } from '@libs/vc-core-lib';
import { IOrgModel } from '@libs/vc-org-lib';
import { Router } from '@angular/router';
import { ThemeMode } from '@libs/vc-common-ui-lib';

@Component({
    selector: 'vc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    langOptions: Language[] = Object.values(Language);
    selectedOrgName: string = '';
    billingIds: string | undefined;
    orgDialogVisible: boolean = false;
    hasChildren: boolean = false;
    showSQLTesting = false;

    @Input()
    set user(value: WhoAmI) {
        this.showSQLTesting = value.hasAuthority(AuthorityUtil.PERMISSION_SQL_TESTER);
        this._user = value;
    }
    get user(): WhoAmI {
        return this._user;
    }
    private _user!: WhoAmI;

    @Input()
    notifications: Notification[] = [];

    @Input()
    isOrgAdmin = false;

    @Input()
    isImpersonating!: string;

    @Input()
    theme!: Theme | null;

    @Input()
    set orgModels(values: IOrgModel[]) {
        this._orgModels = values;
        this._initOrg();

        this.hasChildren = values.length > 1;
    }

    get orgModels(): IOrgModel[] {
        return this._orgModels;
    }

    private _orgModels: IOrgModel[] = [];

    get userName(): string {
        return this.user?.username ?? '';
    }

    @Output()
    changeLang = new EventEmitter<Language>();

    @Output()
    changeTheme = new EventEmitter<Theme>();

    @Output()
    changeOrg = new EventEmitter<string>();

    @Output()
    endImpersonation = new EventEmitter<void>();

    @Output()
    logout = new EventEmitter();

    get lang(): string {
        return this.user.locale as string;
    }

    get themeMode(): ThemeMode {
        return this.theme ?? 'light';
    }

    constructor(private _router: Router) {}

    openOrgDialog() {
        this.orgDialogVisible = true;
    }

    async navigateToNotificationSettings() {
        await this._router.navigate(['notifications-settings']);
    }

    async navigateToNotificationHistory(product?: string) {
        await this._router.navigate(['notification-history'], {
            queryParams: {
                product: product,
            },
        });
    }

    async navigateToAccount() {
        await this._router.navigate(['account']);
    }

    async navigateToOrganization() {
        await this._router.navigate(['admin/organization']);
    }

    changeLanguage(lang: Language) {
        if (lang === this.user.locale) return;
        this.user.locale = lang;
        this.changeLang.emit(lang);
    }

    changeThemeMode(mode: ThemeMode) {
        this.changeTheme.emit(mode as Theme);
    }

    private _initOrg() {
        if (this.orgModels != null) {
            const org: IOrgModel | undefined = this.orgModels.find(
                (model: IOrgModel) => model.id === this.user.getOrgId()
            );
            this.selectedOrgName = org?.name ?? '';
            this.billingIds = TagUtil.getBillingIDString(org?.tags ?? []);
        }
    }
}
