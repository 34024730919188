import { Component, Input } from '@angular/core';
import { NotificationService } from '../notification/notification-service';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'vc-copy-button',
    templateUrl: './copy-button.component.html',
    styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent {
    tooltipText = $localize`:@@COMMON_UI.COPY:Copy`;

    @Input()
    set value(val: string) {
        this._value = val;
        this.tooltipText = $localize`:@@COMMON_UI.COPY_VALUE:Copy ${val}:0:`;
    }
    get value() {
        return this._value;
    }
    private _value!: string;

    /** Specifies if show notification toast after button click */
    @Input()
    showNotification: boolean = false;

    /** Specifies icon color */
    @Input()
    iconColor!: string;

    /** Specifies tooltip position.  Values: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' */
    @Input()
    tooltipPosition: TooltipPosition = 'below';

    constructor(private _notification: NotificationService) {}

    copyToClipboard(event: Event) {
        event.stopPropagation();
        if (!this.value || this.value.length < 1) return;

        navigator.clipboard.writeText(this.value).then(() => {
            if (this.showNotification) {
                this._notification.info(
                    '',
                    $localize`:@@COMMON_UI.NOTIFICATION.COPIED_TO_CLIPBOARD:Copied to clipboard`
                );
            }
        });
    }
}
