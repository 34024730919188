<mat-form-field
    class="vc-select-container vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-readonly]="readonly"
    [floatLabel]="placeholder ? 'always' : 'auto'"
    [class.mat-form-field-invalid]="!valid"
    [class.vc-filter-minimal-styles]="minimalStyles"
    [class.no-padding-bottom]="noPaddingBottom">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input *ngIf="readonly" matInput [readonly]="readonly" [ngModel]="readonlyValue" />
    <mat-select
        *ngIf="!readonly"
        #selectInput
        disableOptionCentering
        [(ngModel)]="value"
        [disabled]="disabled"
        [required]="required"
        [placeholder]="placeholder"
        [hideSingleSelectionIndicator]="true"
        [panelWidth]="minimalStyles ? null : 'auto'"
        [class.mat-select-invalid]="!valid"
        [aria-describedby]="ariaDescribedBy"
        [aria-labelledby]="ariaLabeledBy"
        [attr.aria-invalid]="valid ? 'false' : 'true'"
        (blur)="onBlur()">
        <mat-option *ngFor="let option of options" [value]="option">
            <vc-tooltip-text
                *ngIf="!optionTemplate"
                [text]="itemRenderer ? itemRenderer(option) : option"></vc-tooltip-text>
            <ng-container
                [ngTemplateOutlet]="optionTemplate"
                [ngTemplateOutletContext]="getContextType({ $implicit: option })"></ng-container>
        </mat-option>
    </mat-select>
    <ng-container *ngIf="prefixTemplate && value" matPrefix>
        <ng-container
            [ngTemplateOutlet]="prefixTemplate"
            [ngTemplateOutletContext]="getContextType({ $implicit: value })"></ng-container>
    </ng-container>
    <div matSuffix class="vc-field-suffix">
        <mat-icon *ngIf="suffixIcon && !value">{{ suffixIcon }}</mat-icon>
        <vc-button
            *ngIf="value !== null && value !== undefined && value !== '' && showClear && !disabled && !readonly"
            iconColor="var(--text-high-emphasis)"
            ariaLabel="Clear input"
            iconName="close"
            mode="icon"
            (trigger)="$event.stopPropagation(); clear(); focus()"></vc-button>
    </div>
    <mat-hint *ngIf="!valid" class="mat-error vc-error-message">{{ errorMessage }}</mat-hint>
</mat-form-field>
