<vc-dialog
    [title]="title"
    [primaryLabel]="primaryLabel"
    [(visible)]="visible"
    [closeOnPrimary]="true"
    [primaryDisabled]="!selectedOrgNode"
    (primaryTrigger)="selectOrg()"
    (secondaryTrigger)="filterValue = ''">
    <vc-input
        *ngIf="searchByFilter"
        class="space-bottom-16"
        prefixIcon="search"
        [label]="filterLabel"
        [placeholder]="filterPlaceholder"
        [showClear]="true"
        [(value)]="filterValue"
        (valueChange)="filterValueChange.emit($event)"></vc-input>
        @if (orgTreeNodes && orgTreeNodes.length > 0) {
            <vc-tree
                [showFilter]="!searchByFilter"
                style="width: 700px; overflow-y: hidden"
                [(highlighted)]="selectedOrgNode"
                [showCheckIconForHighlighted]="!showTags"
                [data]="orgTreeNodes"
                [highlightable]="true"
                [vcProgressSpinner]="loading"
                height="400px">
                <ng-template #treeNodeStart let-org>
                    <span [innerHTML]="org.name ?? '' | boldBySearch : filterValue"></span>
                </ng-template>
                <ng-template #treeNodeEnd let-org>
                    <div
                        class="vc-org-picker-node"
                        [style.margin-right]="selectedOrgNode === org ? '8px' : '44px'">
                        @if (showTags) {
                            <vc-chip-tags [tags]="org.tags" [showCount]="1" [wrap]="false"></vc-chip-tags>
                        }
                        @if (selectedOrgNode === org) {
                            <vc-icon
                                class="vc-highlighted-node-icon"
                                name="done"
                                color="var(--text-low-emphasis)"></vc-icon>
                        }
                    </div>
                </ng-template>
            </vc-tree>
        } @else if (orgTreeNodes && orgTreeNodes.length === 0) {
            <div class="vc-org-picker-no-orgs body-m-1">
                <span class="title-m" i18n="@@ORG_PICKER.NO_MATCHES_FOUND">No matches found</span>
                <span i18n="@@ORG_PICKER.TRY_ADDING_MORE_CHARACTERS">Try adding more characters or use another phrase or Org ID</span>
            </div>
        }
</vc-dialog>
