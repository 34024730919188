<vc-dialog
    [(visible)]="visible"
    [loading]="loading"
    [width]="'600px'"
    title="Add folder"
    i18n-title="@@FILE_BROWSER.LABEL.ADD_FOLDER"
    [showActionsOnLeft]="true"
    [showActionsOnRight]="false"
    [closeOnSecondary]="true"
    [clearFormOnClose]="true"
    (primaryTrigger)="save()">
    <div class="body-m-2 space-bottom-16">{{ location }}</div>
    <vc-common-form [showButtons]="false">
        <vc-input
            label="Name"
            i18n-label="@@FILE_BROWSER.LABEL.NAME"
            [required]="true"
            [maxLength]="64"
            [(value)]="folderName"
            [validationFunction]="validateFolderName"></vc-input>
    </vc-common-form>
</vc-dialog>
